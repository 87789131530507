import React from "react";
import {useDispatch} from "react-redux";
import clsx from "clsx";
import {useTypedSelector} from "helpers/reducers";
import {IS_DEV, languages} from "helpers/config";
import {toast} from "react-toastify";
import SessionAction from "store/session/SessionAction";

interface LanguageSelectorProps {
    language: any,
    name: any,
    closeLanguage: any,
    className?: string
    withFlag?: boolean
}

const LanguageSelector = (props: LanguageSelectorProps) => {
    const {language, name, closeLanguage} = props;
    const hallLanguage = useTypedSelector(state => state.hallState.hall?.language.code);
    const activeLanguage = useTypedSelector(state => state.sessionState.language?.code) || hallLanguage;

    if (!activeLanguage) {
        return null;
    }
    const dispatch = useDispatch();

    const setLanguage = (languageCode: string) => {
        const newLanguage = languages.find(lang => lang.code === languageCode);

        if (!newLanguage) {
            toast.error("Lang error");
            return;
        }

        dispatch(SessionAction.setLanguage(newLanguage));
        closeLanguage();
        // window.location.reload();
    };
    return (
        <div
            className={clsx(
                "LanguageSelector relative w-full  flex flex-col justify-center items-center cursor-pointer hover:text-cta-800 focus:text-cta-800 active:text-cta-800",
                language === activeLanguage ? "text-cta-800" : "",
                props.className,
            )}
            onClick={() => setLanguage(language)}
        >
            <div
                className={clsx(
                    "relative flex w-full h-full justify-start items-center z-3 p-2",
                    props.withFlag ? "flex-row" : "flex-col",
                )}
            >
                {props.withFlag && (
                    <div className={"w-6 h-auto mr-2.5"}>
                        <img alt="" className="img-language"
                             src={(IS_DEV ? "https://cl.arbopark.ch" : "") + "/img/flags/flag-" + language + ".svg"} />
                    </div>
                )}
                <div className="font-medium tracking-tight text-lg">{name}</div>
            </div>
        </div>
    );
};

export default LanguageSelector;
