import {RouteComponentProps, withRouter} from "react-router-dom";
import React from "react";
import {useTypedSelector} from "helpers/reducers";
import {Hall} from "types/entities/Hall";
import {HallCode} from "types/enums/HallCode";
import { DEFAULT_HALL, IS_DEV, ROOT_STORAGE_WEB } from "helpers/config";

interface Props extends RouteComponentProps {}

const Logo = (props: Props) => {
    const hall = useTypedSelector<Hall | null>(state => state.hallState.hall) || null;
    const location = props.location.pathname;
    const homeUrl = hall && location.includes("/booking") ? (DEFAULT_HALL === HallCode.ArboPark ? "https://" + hall.domain : "https://" + hall.app_url) : "/";

    return (
        <div className="Logo flex flex-col items-center justify-center box-border h-14 sm:mr-8 sm:mx-0 sm:h-14 min-w-[120px]">
            <div className="w-full h-full flex flex-col justify-center items-center max-w-[180px] sm:max-w-[320px]">
                <a aria-current="page" className={"h-full"} href={homeUrl}>
                    <img
                        alt={hall ? hall.name : "Speeder Logo"}
                        className={"w-full min-w-[180px] sm:min-w-[230px] h-[60px] object-contain"}
                        height={60}
                        src={hall ? (IS_DEV ? hall.image.replace("/img/", ROOT_STORAGE_WEB+"/img/") : hall.image) : ""}
                    />
                </a>
            </div>
        </div>
    );
};

export default withRouter(Logo);
