import CartAction from "./CartAction";
import {ShoppingCart} from "types/entities/ShoppingCart";

export interface ReducerCartState {
    cart: ShoppingCart | null;
}

export interface CartReducerSetCartAction {
    payload: ShoppingCart;
    type: typeof CartAction.SET_CART;
}

type CartReducerAction = CartReducerSetCartAction;

class CartReducer {
    initialState: ReducerCartState = {
        cart: null
    }

    reducer = (state: ReducerCartState = this.initialState, action: CartReducerAction): ReducerCartState => {
        switch (action.type) {
            case CartAction.SET_CART:
                return {
                    ...state,
                    cart: action.payload
                }

            default:
                return state;
        }
    }

}

export default CartReducer;
