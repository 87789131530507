import {AccountDriver, AccountSignatureRequest} from "types/entities/AccountDriver";
import RegistrationAction from "./RegistrationAction";
import update from "immutability-helper";
import {DriverAccessCard, DriverAccessCardResponse} from "types/entities/DriverAccessCard";

export interface ReducerRegistrationState {
    accounts: AccountDriver[];
}

export interface RegistrationSetAccountAction {
    payload: AccountDriver;
    type: typeof RegistrationAction.SET_ACCOUNT;
}

export interface RegistrationRemoveGuestAction {
    payload: AccountDriver;
    type: typeof RegistrationAction.REMOVE_GUEST;
}

export interface RegistrationSetSignatureAction {
    payload: AccountDriver;
    type: typeof RegistrationAction.SET_SIGNATURE;
}

export interface RegistrationSetWristbandAction {
    payload: DriverAccessCardResponse;
    type: typeof RegistrationAction.SET_WRISTBAND;
}

type RegistrationReducerAction = RegistrationSetAccountAction | RegistrationRemoveGuestAction | RegistrationSetSignatureAction | RegistrationSetWristbandAction;

class RegistrationReducer {
    initialState: ReducerRegistrationState = {
        accounts: [],
    };

    reducer = (state: ReducerRegistrationState = this.initialState, action: RegistrationReducerAction): ReducerRegistrationState => {
        switch (action.type) {
            case RegistrationAction.SET_ACCOUNT:
                const accountIndex = state.accounts.findIndex(account => account.id === action.payload.id);

                if (accountIndex === -1) {
                    return update(state, {
                        accounts: {
                            $push: [action.payload],
                        },
                    });
                }

                return update(state, {
                    accounts: {
                        [accountIndex]: {
                            $set: action.payload,
                        },
                    },
                });

            case RegistrationAction.SET_WRISTBAND:
                const accountWristbandIndex = state.accounts.findIndex(account => account.id === action.payload.driver_id);

                return update(state, {
                    accounts: {
                        [accountWristbandIndex]: {
                            driverAccessCard: {
                                $set: action.payload.driverAccessCards,
                            }
                        }
                    },
                });

            case RegistrationAction.REMOVE_GUEST:
                const filteredGuests: AccountDriver[] = [];
                filteredGuests.push(action.payload, ...action.payload.familyAccounts);
                return update(state, {
                    accounts: {
                        $set: filteredGuests,
                    },
                });

            default:
                return state;
        }
    };
}

export default RegistrationReducer;
