import React from "react";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "helpers/reducers";
import {UserCircleIcon} from "@heroicons/react/24/solid";
import {Link} from "react-router-dom";

interface BookingLoginButtonNewProps {
    className?: string;
}

const BookingLoginButtonNew = (props: BookingLoginButtonNewProps) => {
    const {t} = useTranslation();
    const reservations = useTypedSelector(state => state.bookingLoginState.reservations);
    const loginPath = reservations.length === 0 ? "/booking/login" : "/booking/login/reservations";

    return (
        <Link
            className={
                "px-2 py-2 md:px-3 md:py-2 lg:px-4 lg:py-2 font-bold sm:font-semibold lg:font-medium text-sm sm:text-base lg:text-lg group border rounded-md bg-white hover:text-arbo-orange hover:bg-arbo-orange/5 hover:border-arbo-orange hover:shadow-sm text-gray-600 inline-flex items-center"
            }
            to={loginPath}
        >
            <UserCircleIcon className={"w-4 h-4 sm:w-5 sm:h-5 lg:h-6 lg:w-6 flex-none mr-1.5 sm:mr-2 text-green-400 group-hover:text-arbo-orange"} />
            <span className={" duration-0"}>Login</span>
        </Link>
    );
};

export default BookingLoginButtonNew;