import {Friend} from "types/entities/Friend";
import FriendAction from "store/referrals/FriendAction";
import update from "immutability-helper";
import {FriendRequest} from "types/entities/FriendRequest";
import { FriendInvitation } from "types/entities/FriendInvitation";

export interface FriendReducerSetFriendItemAction {
    payload: Friend;
    type: typeof FriendAction.SET_FRIEND_ITEM;
}

export interface FriendReducerUnselectFriendItemAction {
    payload: Friend;
    type: typeof FriendAction.UNSELECT_FRIEND_ITEM;
}

export interface FriendReducerSelectFriendItemAction {
    payload: Friend;
    type: typeof FriendAction.SELECT_FRIEND_ITEM;
}

export interface FriendReducerPushFriendItemAction {
    payload: Friend;
    type: typeof FriendAction.PUSH_FRIEND_ITEM;
}

export interface FriendReducerSetFriendItemsAction {
    payload: Friend[];
    type: typeof FriendAction.SET_FRIEND_ITEMS;
}

export interface FriendReducerDeleteFriendRequestItemAction {
    payload: FriendRequest;
    type: typeof FriendAction.DELETE_FRIEND_REQUEST_ITEM;
}

export interface FriendReducerPushFriendRequestItemAction {
    payload: FriendRequest;
    type: typeof FriendAction.PUSH_FRIEND_REQUEST_ITEM;
}

export interface FriendReducerSetFriendRequestItemsAction {
    payload: FriendRequest[];
    type: typeof FriendAction.SET_FRIEND_REQUEST_ITEMS;
}

export interface FriendReducerDeleteFriendInvitationItemAction {
    payload: FriendInvitation;
    type: typeof FriendAction.DELETE_FRIEND_INVITATION_ITEM;
}

export interface FriendReducerPushFriendInvitationItemAction {
    payload: FriendInvitation;
    type: typeof FriendAction.PUSH_FRIEND_INVITATION_ITEM;
}

export interface FriendReducerSetFriendInvitationItemsAction {
    payload: FriendInvitation[];
    type: typeof FriendAction.SET_FRIEND_INVITATION_ITEMS;
}

export type FriendReducerAction =
    | FriendReducerSetFriendItemAction
    | FriendReducerSetFriendItemsAction
    | FriendReducerSetFriendRequestItemsAction
    | FriendReducerDeleteFriendRequestItemAction
    | FriendReducerPushFriendRequestItemAction
    | FriendReducerSetFriendInvitationItemsAction
    | FriendReducerDeleteFriendInvitationItemAction
    | FriendReducerPushFriendInvitationItemAction
    | FriendReducerUnselectFriendItemAction
    | FriendReducerSelectFriendItemAction
    | FriendReducerPushFriendItemAction;

export interface FriendReducerState {
    friendList: Friend[];
    friendRequests: FriendRequest[];
    friendInvitations: FriendInvitation[];
    selectedFriends: Friend[];
}

class FriendReducer {
    initialState: FriendReducerState = {
        friendList: [],
        friendRequests: [],
        friendInvitations: [],
        selectedFriends: [],
    };

    reducer = (state: FriendReducerState = this.initialState, action: FriendReducerAction): FriendReducerState => {
        switch (action.type) {
            case FriendAction.SET_FRIEND_ITEMS:
                return update(state, {
                    friendList: {
                        $set: action.payload as Friend[],
                    },
                });

            case FriendAction.UNSELECT_FRIEND_ITEM:
                const friendUnselectIndex = state.selectedFriends.findIndex((friendItem: Friend) => {
                    return friendItem.id === ("id" in action.payload ? action.payload.id : null)
                });

                if (friendUnselectIndex === -1) {
                    return state;
                }

                return update(state, {
                    selectedFriends: {
                        $splice: [[friendUnselectIndex, 1]],
                    },
                });

            case FriendAction.SELECT_FRIEND_ITEM:
                const friendIndex = state.selectedFriends.findIndex((friendItem: Friend) => {
                    return friendItem.id === ("id" in action.payload ? action.payload.id : null)
                });
                if (friendIndex !== -1) {
                    return state;
                }
                return update(state, {
                    selectedFriends: {
                        $push: [action.payload as Friend],
                    },
                });

            case FriendAction.PUSH_FRIEND_ITEM:
                const friendItemIndex = state.friendList.findIndex((friendItem: Friend) => {
                    return friendItem.id === ("id" in action.payload ? action.payload.id : null)
                });
                if(friendItemIndex !== -1) {
                    return update(state, {
                        friendList: {
                            [friendItemIndex]: {
                                $set: action.payload as Friend
                            }
                        }
                    })
                }
                return update(state, {
                    friendList: {
                        $push: [action.payload as Friend],
                    },
                });

            case FriendAction.DELETE_FRIEND_REQUEST_ITEM:
                const friendRequestIndex = state.friendRequests.findIndex((friendRequest: FriendRequest) => {
                    return friendRequest.id === ("id" in action.payload ? action.payload.id : null)
                });

                if (friendRequestIndex === -1) {
                    return state;
                }

                return update(state, {
                    friendRequests: {
                        $splice: [[friendRequestIndex, 1]],
                    },
                });

            case FriendAction.SET_FRIEND_REQUEST_ITEMS:
                return update(state, {
                    friendRequests: {
                        $set: action.payload as FriendRequest[],
                    },
                });

            case FriendAction.PUSH_FRIEND_REQUEST_ITEM:
                return update(state, {
                    friendRequests: {
                        $push: [action.payload as FriendRequest],
                    },
                });

            case FriendAction.DELETE_FRIEND_INVITATION_ITEM:
                const friendInvitationIndex = state.friendInvitations.findIndex((friendInvitation: FriendInvitation) => {
                    return friendInvitation.id === ("id" in action.payload ? action.payload.id : null)
                });

                if (friendInvitationIndex === -1) {
                    return state;
                }

                return update(state, {
                    friendInvitations: {
                        $splice: [[friendInvitationIndex, 1]],
                    },
                });

            case FriendAction.SET_FRIEND_INVITATION_ITEMS:
                return update(state, {
                    friendInvitations: {
                        $set: action.payload as FriendInvitation[],
                    },
                });

            case FriendAction.PUSH_FRIEND_INVITATION_ITEM:
                return update(state, {
                    friendInvitations: {
                        $push: [action.payload as FriendInvitation],
                    },
                });

            default:
                return state;
        }
    };
}

export default FriendReducer;
